.com-ticket-type---description {
	padding: 0 10pt 0 11.2pt !important;
	font-size: 10pt;
}

.com-ticket-type---quantity {
	padding: 0 10pt 0 11.2pt !important;
	font-size: 10pt;
	opacity: 0.75;
	font-weight: bold;
}