.md-cashdesk-checkout---container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	height: auto;
	width: 100vw;
	height: 100vh;
	padding: var(--padding---global---modal) var(--padding---global---modal) 150pt var(--padding---global---modal);
	> h6 {
		padding: 0;
		text-align: center;
	}
	> h4 {
		padding: 0;
		margin: 0;
		text-align: center;
		font-weight: bold;
	}
	> h2 {
		width: 100%;
		background-color: var(--color---shadow);
		padding: var(--padding---global---modal);
		border-radius: 6pt;
		text-align: center;
		font-weight: bold;
	}
}

.container---footer-section---check-in {
	display: flex;
	flex-direction: column;
	height: 230pt;
	gap: 10pt;
	> button {
		&:nth-child(2) {
			height: 160pt;
			line-height: 160pt;
			> h3 {
				text-align: center;
				height: 160pt;
				line-height: 160pt;
				font-weight: bold !important;
			}
		}
	}
}